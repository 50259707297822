
import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
  components: {
    Banner
  },
  data() {
    return {
      area: '',
      legislacoesGeral: [],
      legEstadual: [],
      legMunicipal: [],
      blLoading: false,
      noResult: false,
      estadual: false,
      municipal: false,
      estado: undefined,
      ufs: [
        {
          'estado': 'Rondônia',
          'uf': 'RO'
        },
        {
            'estado': 'Acre',
            'uf': 'AC'
        },
        {
            'estado': 'Amazonas',
            'uf': 'AM'
        },
        {
            'estado': 'Roraima',
            'uf': 'RR'
        },
        {
            'estado': 'Pará',
            'uf': 'PA'
        },
        {
            'estado': 'Amapá',
            'uf': 'AP'
        },
        {
            'estado': 'Tocantins',
            'uf': 'TO'
        },
        {
            'estado': 'Maranhão',
            'uf': 'MA'
        },
        {
            'estado': 'Piauí',
            'uf': 'PI'
        },
        {
            'estado': 'Ceará',
            'uf': 'CE'
        },
        {
            'estado': 'Rio Grande do Norte',
            'uf': 'RN'
        },
        {
            'estado': 'Paraíba',
            'uf': 'PB'
        },
        {
            'estado': 'Pernambuco',
            'uf': 'PE'
        },
        {
            'estado': 'Alagoas',
            'uf': 'AL'
        },
        {
            'estado': 'Sergipe',
            'uf': 'SE'
        },
        {
            'estado': 'Bahia',
            'uf': 'BA'
        },
        {
            'estado': 'Minas Gerais',
            'uf': 'MG'
        },
        {
            'estado': 'Espírito Santo',
            'uf': 'ES'
        },
        {
            'estado': 'Rio de Janeiro',
            'uf': 'RJ'
        },
        {
            'estado': 'São Paulo',
            'uf': 'SP'
        },
        {
            'estado': 'Paraná',
            'uf': 'PR'
        },
        {
            'estado': 'Santa Catarina',
            'uf': 'SC'
        },
        {
            'estado': 'Rio Grande do Sul',
            'uf': 'RS'
        },
        {
            'estado': 'Mato Grosso do Sul',
            'uf': 'MS'
        },
        {
            'estado': 'Mato Grosso',
            'uf': 'MT'
        },
        {
            'estado': 'Goiás',
            'uf': 'GO'
        },
        {
          'estado': 'Distrito Federal',
          'uf': 'DF'
        }
      ],
      arBreadcrumbs: [
        {
          text: "Início",
          to: "/"
        },
        {
          text: "COMEX",
          to: "/"
        },
        {
          text: "Legislações"
        }
      ],
    }
  },
  watch: {
    estado() {
      this.legMunicipal = [];
      this.legEstadual = [];
      this.buscaLeg(this.estado.toLowerCase());
    }
  },
  created() {
    this.arBreadcrumbs[1].text = this.$route.params.area.charAt(0).toUpperCase() + this.$route.params.area.slice(1);
    this.ufs.sort((a,b) => {
      return (a.estado < b.estado) ? -1 : (a.estado > b.estado) ? 1 : 0;
    });
    if (this.$route.params.area == 'federal') {
      this.federal = true;
      this.buscaLeg();
    } else if (this.$route.params.area == 'estadual') {
      this.estadual = true;
    } else if (this.$route.params.area == 'municipal') {
      this.municipal = true;
    }
  },
  methods: {
    buscaLeg() {
      this.blLoading = true;
      this.noResult = false;
      if (this.estadual) {
        let est = 'lgpd-leg-'+this.estado+'-est';
        let urlLeg = "https://www.econeteditora.com.br/links_pagina_inicial/area_especial_acessorias_trabalhista/retorna_legislacoes.php?leg=" + est;
        this.$root.$api.getUrlCompleta(urlLeg).then((response) => {
          this.legEstadual = response;
          this.ordenarLegislacoes(this.legEstadual);
          this.blLoading = false;
          this.noResult = response.length == 0 ? true : false;
        }); 
      } else if (this.municipal) {
        let mun = 'lgpd-leg-'+this.estado+'-mun';                    
        let urlMun = "https://www.econeteditora.com.br/links_pagina_inicial/area_especial_acessorias_trabalhista/retorna_legislacoes.php?leg=" + mun; 
        this.$root.$api.getUrlCompleta(urlMun).then((response) => {
          this.legMunicipal = response;
          this.ordenarLegislacoes(this.legMunicipal);
          this.blLoading = false;
          this.noResult = response.length == 0 ? true : false;
        }); 
      } else if (this.federal) {
        let url = "https://www.econeteditora.com.br/links_pagina_inicial/area_especial_acessorias_trabalhista/retorna_legislacoes.php?leg=lgpd-leg-nac";
        this.$root.$api.getUrlCompleta(url).then((response) => {
          this.legislacoesGeral = response;
          this.ordenarLegislacoes(this.legislacoesGeral);
          this.blLoading = false;
          this.noResult = response.length == 0 ? true : false;
        });
      }
    },
    ordenarLegislacoes(lista) {
      lista.sort((a, b) => {
        if (a.estado < b.estado) {
          return -1;
        } else if (a.estado > b.estado) {
          return 1;
        } else {
          return a.chegada - b.chegada;
        }
      });
    }
  },
}